import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "organizing-page-content-with-landmark-regions"
    }}>{`Organizing Page Content with Landmark Regions`}</h2>
    <p>{`Web design begins with a foundational layout, often including a page header and footer, site
navigation, main content area, and more.`}</p>
    <p>{`When a page is visually organized into sections but the purpose of those sections is not
communicated to screen reader users, it can make understanding content more complicated for these
users.`}</p>
    <p>{`This is why it is essential to divide our page into 'landmark' sections or regions to help
communicate content structure for users who cannot see the screen.`}</p>
    <h3 {...{
      "id": "how-landmark-regions-can-improve-screen-reader-experience"
    }}>{`How Landmark Regions Can Improve Screen Reader Experience`}</h3>
    <p>{`Landmark regions are a means to improve wayfinding and orientation for screen reader users.`}</p>
    <p>{`You can use HTML5 sectional elements and WAI-ARIA landmark roles to help screen reader users
understand the layout of the user interface.`}</p>
    <p>{`A screen reader user can pull up a list of the landmarks on a page to quickly understand the
essential parts and organization. They can then jump directly to a landmark without listening to the
entire page from top to bottom.`}</p>
    <h3 {...{
      "id": "html5-landmark-regions"
    }}>{`HTML5 Landmark Regions`}</h3>
    <p>{`HTML5 has several elements for structuring the content into valuable regions.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<header>`}</inlineCode>{` - Document header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<nav>`}</inlineCode>{` - Site navigation`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<main>`}</inlineCode>{` - Main body content`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<section>`}</inlineCode>{` - Document section (generic)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<article>`}</inlineCode>{` - Independent, self-contained body content`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<aside>`}</inlineCode>{` - Supplementary content`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<footer>`}</inlineCode>{` - Document footer`}</li>
    </ul>
    <p>{`Ensure that all content on the page is encapsulated inside a sectioning element so there is no
"orphaned" content.`}</p>
    <p>{`When using the `}<inlineCode parentName="p">{`<main>`}</inlineCode>{` element or `}<inlineCode parentName="p">{`role= "main"`}</inlineCode>{`, ensure it encloses the page's primary content. It
shouldn't include sections like the site-wide navigation, header, or supplemental content specific
to the page.`}</p>
    <p>{`We can illustrate how the following document landmarks can be included in a page layout.`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`<header>`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<nav>`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<main>`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<aside>`}</inlineCode></li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5b3ab22fd4b3add2ce4987893fb96dcd/536c7/accessibility-page-structure-img1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.78378378378379%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration of a generic page layout showing document landmarks including 1 header, 2 nav, 3 main and 4 aside",
            "title": "Illustration of a generic page layout showing document landmarks including 1 header, 2 nav, 3 main and 4 aside",
            "src": "/static/5b3ab22fd4b3add2ce4987893fb96dcd/50383/accessibility-page-structure-img1.png",
            "srcSet": ["/static/5b3ab22fd4b3add2ce4987893fb96dcd/1efb2/accessibility-page-structure-img1.png 370w", "/static/5b3ab22fd4b3add2ce4987893fb96dcd/50383/accessibility-page-structure-img1.png 740w", "/static/5b3ab22fd4b3add2ce4987893fb96dcd/536c7/accessibility-page-structure-img1.png 1480w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "aria--landmark-regions"
    }}>{`ARIA & Landmark Regions`}</h3>
    <p>{`The WAI-ARIA specification also has a corresponding set of "landmark" roles that can be used to
convey the same information as HTML5 landmark regions.`}</p>
    <p>{`We should only use the corresponding WAI-ARIA landmark roles when using the HTML5 sectioning
elements is not possible.`}</p>
    <h3 {...{
      "id": "duplicate-landmarks"
    }}>{`Duplicate landmarks`}</h3>
    <p>{`Duplicate landmarks should have uniquely different names.`}</p>
    <p>{`For example, if you have two `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{` region elements in the document, differentiate between them by
giving them unique names.`}</p>
    <h3 {...{
      "id": "further-information"
    }}>{`Further Information`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/example-index/landmarks/index.html"
        }}>{`W3C ARIA Landmarks Example`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=IhWMou12_Vk"
        }}>{`Youtube - Leonie Watson - How ARIA landmark roles help screen reader users`}</a></li>
    </ul>
    <h2 {...{
      "id": "headings"
    }}>{`Headings`}</h2>
    <p>{`Content design often uses visual styling attributes like font size, weight, and color to convey the
relative importance and structure of content provided on a page.`}</p>
    <p>{`However, not everyone will be able to perceive visual font styling and arrive at the same
understanding of the content structure.`}</p>
    <p>{`HTML markup offers six levels of heading tags `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` to`}<inlineCode parentName="p">{`<h6>`}</inlineCode>{` to help create a more accessible
content structure that does not depend entirely on visual font styling.`}</p>
    <h3 {...{
      "id": "how-headings-relate-to-screen-reader-experience"
    }}>{`How Headings Relate to Screen Reader Experience`}</h3>
    <p>{`Heading markup provides additional benefits to the user experience as well.`}</p>
    <p>{`When a sighted user scans a website or application, they take note of headings, controls, and visual
groupings. Headings create a "document outline" that users with impaired vision can use to get the
same sense of the content's structure.`}</p>
    <p>{`The headings on a page are the primary means of achieving this structure, so headings must be clear,
descriptive, logical, and comprehensible.`}</p>
    <p>{`Note that it is also vital to consider landmarks together with the heading structure. Where
possible, they should reinforce each other.`}</p>
    <h3 {...{
      "id": "who-is-responsible-for-defining-page-headings"
    }}>{`Who is Responsible for Defining Page Headings?`}</h3>
    <p>{`Page headings and heading levels must be decided during the design stages, then communicated to
development.`}</p>
    <h3 {...{
      "id": "content-heading-guidelines"
    }}>{`Content Heading Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`The main part of your page should begin with a level 1 heading, clearly describing the page's
primary purpose.`}</li>
      <li parentName="ul">{`There should be one and only one `}<inlineCode parentName="li">{`h1`}</inlineCode>{` on the page.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`h1`}</inlineCode>{` should be the first heading inside the `}<inlineCode parentName="li">{`main`}</inlineCode>{` landmark.`}</li>
      <li parentName="ul">{`Heading levels should align with the visual importance of the heading. Level 1 represents the most
significant, most prominent title text on the page.`}</li>
      <li parentName="ul">{`Heading levels are intended to be used to represent the content hierarchy. Skipping heading levels
should be avoided if possible. Where unavoidable, minimise the impact of the skipped levels (e.g.
Avoid following a level 1 heading with a level 5 heading.).`}</li>
    </ul>
    <h3 {...{
      "id": "further-information-"
    }}>{`Further Information :`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/page-structure/headings/"
        }}>{`Headings - Page Structure Concepts - WAI Web Accessibility Tutorials`}</a></li>
    </ul>
    <h2 {...{
      "id": "lists"
    }}>{`Lists`}</h2>
    <p>{`When content is visually shown to express structure, such as a heading or a list, that same
structure should be determined from the markup.`}</p>
    <p>{`Assistive technology users will not understand the relationships conveyed visually in the content
layout without semantic HTML elements. Without using the correct markup, users will not navigate to
and within lists using the features their assistive technology software provides.`}</p>
    <h3 {...{
      "id": "how-lists-relate-to-screen-reader-experience"
    }}>{`How Lists Relate to Screen Reader Experience`}</h3>
    <p>{`Using HTML lists provides a screen reader with information such as the number of items in the list
and what they are currently listening to. This provides a comparable audible experience to what is
presented visually.`}</p>
    <p>{`Lists are often thought of as simple numbered `}<inlineCode parentName="p">{`<ol>`}</inlineCode>{` or bulleted `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` lists of items.`}</p>
    <p>{`But, it can also be helpful to use HTML list markup in more creative ways to group related items
together.`}</p>
    <p>{`For example, a visual diagram showing the user which step they are currently working on out of a
total number of steps in a more significant, multi-step flow.`}</p>
    <h4 {...{
      "id": "example"
    }}>{`Example`}</h4>
    <p>{`A great example of this is to use a list to express chronologically ordered messages in a
conversation thread.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3e1252aad06b899c96b2415bb82edee5/536c7/accessibility-page-structure-messages.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.78378378378379%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Stylised illustration of a message thread with four items",
            "title": "Stylised illustration of a message thread with four items",
            "src": "/static/3e1252aad06b899c96b2415bb82edee5/50383/accessibility-page-structure-messages.png",
            "srcSet": ["/static/3e1252aad06b899c96b2415bb82edee5/1efb2/accessibility-page-structure-messages.png 370w", "/static/3e1252aad06b899c96b2415bb82edee5/50383/accessibility-page-structure-messages.png 740w", "/static/3e1252aad06b899c96b2415bb82edee5/536c7/accessibility-page-structure-messages.png 1480w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "example-1"
    }}>{`Example`}</h4>
    <p>{`Another great example is to use a list to help communicate the sibling relationship between items in
a row of related cards.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/727fc18cc6b6f06ce6bb4311928df878/536c7/accessibility-page-structure-org-chart.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.78378378378379%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Stylised illustration showing a horizontal row of user profile cards representing colleagues in team",
            "title": "Stylised illustration showing a horizontal row of user profile cards representing colleagues in team",
            "src": "/static/727fc18cc6b6f06ce6bb4311928df878/50383/accessibility-page-structure-org-chart.png",
            "srcSet": ["/static/727fc18cc6b6f06ce6bb4311928df878/1efb2/accessibility-page-structure-org-chart.png 370w", "/static/727fc18cc6b6f06ce6bb4311928df878/50383/accessibility-page-structure-org-chart.png 740w", "/static/727fc18cc6b6f06ce6bb4311928df878/536c7/accessibility-page-structure-org-chart.png 1480w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "further-information--1"
    }}>{`Further Information :`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/page-structure/content/#lists"
        }}>{`Lists - Page Structure Concepts - WAI Web Accessibility Tutorials`}</a></li>
    </ul>
    <h2 {...{
      "id": "tables"
    }}>{`Tables`}</h2>
    <p>{`Content is often arranged into rows and columns on a page to better use screen real estate.
Sometimes, the content will have direct logical relationships in the rows and columns, such as in a
calendar or schedule. These relationships must be maintained for users who cannot perceive the
visual layout of the content in the rows and columns.`}</p>
    <p>{`HTML markup offers the `}<inlineCode parentName="p">{`<table>`}</inlineCode>{` element that can be used when a design calls for data with logical
relationships.`}</p>
    <h3 {...{
      "id": "how-tables-relate-to-screen-reader-experience"
    }}>{`How Tables Relate to Screen Reader Experience`}</h3>
    <p>{`Screen readers allow users to navigate a table while automatically providing contextual column
header names regardless of which row they are focussed on.`}</p>
    <p>{`Similarly, users can also traverse data cells vertically. If applicable to the design, screen
readers can also automatically provides a contextual row header.`}</p>
    <h3 {...{
      "id": "further-information--2"
    }}>{`Further Information :`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/tables/"
      }}>{`Table Concepts - WAI Web Accessibility Tutorials`}</a></p>
    <h3 {...{
      "id": "table-guidelines"
    }}>{`Table Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`<table>`}</inlineCode>{` must not be used for layout and presentation. It should be used to markup tabular data
and information.`}</li>
      <li parentName="ul">{`Data tables must provide column headers and should provide row headers.`}</li>
      <li parentName="ul">{`Avoid empty column headers in data tables.`}</li>
      <li parentName="ul">{`Where applicable, the first column of a data table should provide descriptive row headers.`}</li>
      <li parentName="ul">{`Provide a descriptive heading or caption to help introduce users to the data presented in the
table.`}</li>
      <li parentName="ul">{`Avoid nesting form controls inside table cells, since this will increase the information a screen
reader user must process and comprehend.`}</li>
      <li parentName="ul">{`Avoid complex tables. Instead, consider breaking it into multiple tables or presenting data in
another format.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      